<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">分销管理</a>
          <i>></i>
          <a href="javascript:;">申请列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div class="searchboxItem">
              <span class="itemLabel">报名单位名称:</span>
              <el-input
                v-model="comName"
                type="text"
                size="small"
                style="width: auto"
                placeholder="请输入报名单位名称"
                clearable
              />
            </div>
            <div>
              <el-button type="primary" @click="getData()">查询</el-button>
              <el-button type="primary" @click="exportData()">导出</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
            >
              <el-table-column label="序号" align="center" type="index" />
              <el-table-column
                label="报名单位名称"
                align="center"
                prop="comName"
                :show-overflow-tooltip="true"
              />
              <el-table-column
                label="报名者姓名"
                align="center"
                prop="realname"
                :show-overflow-tooltip="true"
              />
              <el-table-column label="报名者电话" align="center" prop="phone" />
              <el-table-column
                label="报名者职务"
                align="center"
                prop="position"
              />
              <el-table-column label="备注" align="center" prop="remark" />
              <el-table-column
                label="报名时间"
                align="center"
                prop="applyTime"
              />
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @getData="getData"
          @sizeChange="sizeChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data: function () {
    return {
      comName: "",
      tableData: [],
    };
  },
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        comName: this.comName,
      };
      this.doFetch(
        {
          url: "/miniapp/com/apply/list",
          params,
          pageNum,
        },
        true,
        2
      );
    },
    exportData() {
      const params = {
        comName: this.comName,
      };
      this.$post("/miniapp/com/apply/list/export", params, 3000, true, 2).then(
        (res) => {
          if (res.status == "0") {
            window.open(res.data);
          }
        }
      );
    },
  },
};
</script>

<style>
</style>